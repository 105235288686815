import React from "react"
import BaseHeading from "../Heading"
import { FaLinkedin } from "react-icons/fa"
import { Link } from "gatsby"
export default function Company() {
  return (
    <div className="h-full text-xl p-8 md:p-16 space-y-16">
      <div className="flex-1 flex flex-col p-8 sm:p-16 bg-blue-atl text-white space-y-4 rounded">
        <p className="font-extrabold">
          brantpoint steht für Tax Technology und steuerzentrierte
          Prozessberatung.
        </p>
        <p>
          Wir entwickeln Lösungen, die den digitalen Reifegrad steuerlicher
          Prozesse und damit Ihrer Organisation deutlich erhöhen.
        </p>
        <p>
          Der Ursprung von brantpoint liegt in der multidisziplinären Sozietät
          Peters, Schönberger & Partner, eine Kanzlei mit ausgeprägter
          IT-Expertise an der Schnittstelle zwischen Steuerrecht und
          Informationstechnologie.
        </p>
      </div>
      <div className="font-bold text-blue-atl text-3xl md:text-5xl">
        Management Team
      </div>
      <div className="flex flex-col lg:flex-row lg:space-x-24 md:w-1/2">
        <div className="flex-1 flex flex-col">
          <img
            className="w-full mt-12 sm:w-3/4 lg:w-full lg:mt-0 rounded"
            src="/sgr.jpg"
            alt="Stefan Groß"
          />
          <div className="flex flex-col mt-8 space-y-4">
            <div className="font-bold text-blue-atl">Stefan Groß</div>

            <div className="text-base">
              Ist als Geschäftsführer für alles zuständig, was bei brantpoint
              mit Finanzen und Außenauftritt zu tun hat. Liebt alte Autos und
              gute Weine.
            </div>
            <a
              href="https://de.linkedin.com/in/stefan-gro%C3%9F-585a4b25"
              target="_blank"
              className="text-3xl text-gray-300 hover:text-gray-500 cursor-pointer"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>

        <div className="flex-1 flex flex-col">
          <img
            className="w-full mt-12 sm:w-3/4 lg:w-full lg:mt-0 rounded"
            src="/dsc.jpg"
            alt="Daniel Scherzer"
          />
          <div className="flex flex-col mt-8 space-y-4">
            <div className="font-bold text-blue-atl">Daniel Scherzer</div>

            <div className="text-base">
              Ist als technischer Berater zuständig für alles, was bei brantpoint mit Technik zu
              tun hat. Liebt Mountainbiken und Pizza.
            </div>
            <a
              href="https://www.linkedin.com/in/daniel-scherzer-21b406112"
              target="_blank"
              className="text-3xl text-gray-300 hover:text-gray-500 cursor-pointer"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
      {/* <div className="font-bold text-blue-atl text-3xl md:text-5xl">
        Offene Stellen
      </div>
      <ul className="">
        <li className="p-4 border-b border-gray-200 hover:bg-gray-100">
          <Link to={"/positions/1"}>Senior Golang Developer</Link>
        </li>
        <li className="p-4 border-b border-gray-200 hover:bg-gray-100">
          <Link to={"/positions/2"}>Junior Golang Developer</Link>
        </li>
        <li className="p-4 border-b border-gray-200 hover:bg-gray-100">
          <Link to={"/positions/3"}>React Developer</Link>
        </li>
        <li className="p-4 border-b border-gray-200 hover:bg-gray-100">
          <Link to={"/positions/4"}>DevOps Engineer</Link>
        </li>
        <li className="p-4 border-b border-gray-200 hover:bg-gray-100">
          <Link to={"/positions/5"}>Mitarbeiter:in für den Vertrieb</Link>
        </li>
      </ul> */}
    </div>
  )
}
